// Import all of Bootstrap's JS
import * as bootstrap from "bootstrap";
import "htmx.org";
import TomSelect from "tom-select";
import Pristine from "pristinejs";
import DirtyForm from "dirty-form";

function dashboard() {
  /**
   * Toasts / Hinweisboxen initialisieren
   */
  let showToasts = function () {
    $messages = document.getElementById("frontend-messages");

    if ($messages === undefined || $messages === null) return;

    $messages.querySelectorAll(".toast").forEach((el) => {
      const toast = new bootstrap.Toast(el);
      toast.show();
    });
  };

  showToasts();
  document.body.addEventListener("htmx:afterSwap", (e) => {
    showToasts();

    if (e.target.id === "modals") {
      const $modal = e.target.querySelector("#ksModal");
      const m = new bootstrap.Modal($modal);
      m.show();

      $modal.addEventListener("submit", () => {
        m.hide();
      });

      $modal.addEventListener("shown.bs.hidden", () => {
        m.dispose();
      });
    }
  });

  document.body.addEventListener("kathstat:reload", (e) => {
    if (e.detail.url !== undefined) {
      window.location = e.detail.url;
    } else {
      window.location.reload();
    }
  });

  /**
   * Select-Boxen initialisieren
   */
  document.body.querySelectorAll(".tom-select").forEach((el) => {
    new TomSelect(el, {});
  });

  /**
   * Bestätigungsmodal bauen
   */
  let $confirmModal = document.querySelector("#confirmModal");
  if ($confirmModal !== undefined && $confirmModal !== null) {
    const confirmModal = new bootstrap.Modal($confirmModal);

    document.body.addEventListener("click", (ev) => {
      const $el = ev.target.closest("[data-confirm-modal]");
      if ($el === null || $el === undefined) return;
      const $form = document.querySelector("#" + $el.getAttribute("form"));
      if ($form === null || $form === undefined) return;

      ev.preventDefault();
      confirmModal._dialog.querySelector("h3").innerHTML =
        $form.dataset.confirmTitle;
      confirmModal._dialog.querySelector("span").innerHTML =
        $form.dataset.confirmText;
      confirmModal._dialog
        .querySelector("button[data-confirm-submit]")
        .addEventListener("click", (ev) => {
          ev.preventDefault();
          $form.submit();
          confirmModal.hide();
        });
      confirmModal.show();
    });
  }

  /**
   * Hinweismodal für fehlerhafte Formulare
   */

  let $questionnaireModal = document.querySelector("#ksQuestionnaireModal");
  let questionnaireModal;
  if ($questionnaireModal !== undefined && $questionnaireModal !== null) {
    questionnaireModal = new bootstrap.Modal($questionnaireModal);
  }

  /**
   * Checkbox selektieren, wenn ein List-Tile geklickt wird
   */
  document.querySelectorAll(".ks-list-tile").forEach((el) => {
    const $checkbox = el.querySelector("input[type=checkbox]");
    el.addEventListener("click", (e) => {
      if ((e.target.nodeName == "A") | (e.target.nodeName == "INPUT")) return;
      $checkbox.checked = !$checkbox.checked;
    });
  });

  /**
   * Select-All-Schalter
   */
  let selectAllToggle = false;
  let $selectAllToggle = document.querySelector(".ks-select-all");
  if ($selectAllToggle !== undefined && $selectAllToggle !== null) {
    $selectAllToggle.addEventListener("click", (e) => {
      e.preventDefault();
      selectAllToggle = !selectAllToggle;
      document
        .querySelectorAll(".ks-list-tile input[type=checkbox]")
        .forEach((el) => {
          el.checked = selectAllToggle;
        });
    });
  }

  /**
   * URL-Selectbox
   */
  document.querySelectorAll(".ks-select-url").forEach((el) => {
    el.addEventListener("change", (e) => {
      window.location = el.options[el.selectedIndex].dataset.url;
    });
  });

  /**
   * Speicher-Hinweis für Formulare
   */
  document.querySelectorAll(".ks-dirty-check").forEach(($form) => {
    new DirtyForm($form, {
      message:
        "Das Formular enthält ungespeicherte Änderungen. Wollen Sie es wirklich verlassen?",
    });
  });

  /**
   * Formular-Validierung mittels Pristine
   */
  Pristine.addMessages("de", {
    required:
      "Dieses Feld ist verpflichtend. Bitte tragen Sie einen Wert hier ein.",
    email: "Bitte geben Sie eine gültige E-Mailadresse in dieses Feld ein.",
    number:
      "In diesem Feld sind nur Zahlen erlaubt. Bitte tragen Sie eine Zahl mittels der Zahlentasten ein.",
    integer:
      "In diesem Feld sind nur Zahlen erlaubt. Bitte tragen Sie eine Zahl mittels der Zahlentasten ein.",
    url: "In dieses Feld muss eine gültige Internetadresse eingetragen werden.",
    tel: "In dieses Feld muss eine gültige Telefonnummer eingetragen werden.",
    maxlength: "Bitte geben Sie weniger als ${1} Zeichen ein.",
    minlength: "Bitte geben Sie hier mindestens > ${1} Zeichen ein.",
    min: "Der Wert in diesem Feld muss mindestens ${1} sein.",
    max: "Der Wert in diesem Feld darf maximal ${1} sein.",
    pattern: "Bitte geben Sie den Wert im angegebenen Format ein",
    equals: "Die zwei Felder stimmen nicht überein",
    default: "Bitte geben Sie einen korrekten wert ein.",
  });
  Pristine.addValidator(
    "number-or-n",
    (v, maxvalue) => {
      // Leeres Feld ist erlaubt
      if (!v) return true;
      // N/n ist erlaubt
      if (v === "N" || v === "n") return true;
      // Keine Zahl soll einen Fehler produzieren!
      if (isNaN(parseFloat(v))) {
        return false;
      }
      // Falsche Zahl soll einen Fehler produzieren!
      if (parseFloat(v) > parseFloat(maxvalue)) {
        return false;
      }
      // Rest egal!
      return true;
    },
    "Der Wert in diesem Feld muss eine Zahl sein und darf maximal ${1} sein. Lassen Sie das Feld leer, wenn das Gefragte nicht vorhanden ist. Tragen Sie N ein, wenn das Gefragte vorhanden ist, aber der Zahlenwert nicht angegeben werden kann.",
    99
  );
  Pristine.setLocale("de");

  document.querySelectorAll(".ks-action-button").forEach((btn) => {
    btn.addEventListener("click", (e) => {
      document.querySelector("input[name=action]").value = btn.getAttribute("name") + ":" + btn.value;
    });
  });

  document.querySelectorAll("form.ks-validation").forEach((form) => {
    let pristine = new Pristine(form, {
      classTo: "ks-statform--row",
      errorClass: "text-danger",
      successClass: "has-success",
      // class of the parent element where error text element is appended
      errorTextParent: "ks-question-label",
      // type of element to create for the error text
      errorTextTag: "div",
      // class of the error text element
      errorTextClass: "text-help",
    });

    form.addEventListener("submit", (e) => {
      if (form.dataset.isValid === "true") {
        return true;
      }

      e.preventDefault();
      let valid = pristine.validate();

      if (!valid && questionnaireModal) {
        questionnaireModal.show();
      } else {
        form.dataset.isValid = "true";
        form.submit();
      }
    });

    /** 
     * Formularfeld leeren
     */
    form.querySelectorAll(".ks-empty-field").forEach(($btn) => {
      $btn.addEventListener("click", (e) => {
        e.preventDefault();
        let $input = $btn.parentElement.querySelector("input[type=text]");
        $input.value = "";
        pristine.validate();
      });
    });
  });

  document.querySelectorAll("form.ks-calculation").forEach(($form) => {
    if (formCalculations == undefined || formCalculations == null) return;

    formCalculations.forEach(($c) => {
      let $input = document.getElementById($c.field_id);
      $c.calculations.forEach((calculation) => {
        let $f;
        switch (calculation.method) {
          case "sum":
            calculation.field_ids.forEach((fieldname) => {
              $f = document.querySelector('input[name="q-' + fieldname + '"]');
              if ($f === undefined || $f === null) {
                console.log("Unbekanntes Feld: ", fieldname);
                return;
              }

              $f.addEventListener("change", (e) => {
                let value = 0;
                calculation.field_ids.forEach((fieldname) => {
                  let $f = document.querySelector(
                    'input[name="q-' + fieldname + '"]'
                  );
                  if ($f === undefined || $f === null) return;
                  let n = Number($f.value.replace(",", "."));
                  if (!isNaN(n)) {
                    value += n;
                  }
                });
                $input.value = value;
              });
            });
            break;

          case "calculate_fte_wh":
            if (
              formVariables.fte_week_worktime == undefined ||
              formVariables.fte_week_worktime == null
            )
              return;

            $f = document.querySelector(
              'input[name="q-' + calculation.field_id + '"]'
            );

            if ($f === undefined || $f === null) {
              console.log("Unbekanntes Feld: ", calculation.field_id);
              return;
            }

            $f.addEventListener("change", (e) => {
              let n = Number($f.value.replace(",", "."));
              if (isNaN(n)) {
                return;
              }
              $input.value = (n / formVariables.fte_week_worktime).toFixed(2).toString().replace('.', ',');
            });

            break;

          case "calculate_fte_yh":
            if (
              formVariables.fte_year_worktime == undefined ||
              formVariables.fte_year_worktime == null
            )
              return;

            $f = document.querySelector(
              'input[name="q-' + calculation.field_id + '"]'
            );

            if ($f === undefined || $f === null) {
              console.log("Unbekanntes Feld: ", calculation.field_id);
              return;
            }

            $f.addEventListener("change", (e) => {
              let n = Number($f.value.replace(",", "."));
              if (isNaN(n)) {
                return;
              }
              $input.value = (n / formVariables.fte_year_worktime).toFixed(2).toString().replace('.', ',');
            });

            break;

          default:
            break;
        }
        $input.dispatchEvent(new Event("change"));
      });
    });
  });


  /**
   * Select-All-Schalter
   */
  document.addEventListener("click", (e) => {
    if (!e.target.classList.contains("ks-takeover-value")) {
      return;
    }
    e.preventDefault();

    let $previous = document.getElementById("previous_answer_value");
    let $field = document.getElementById($previous.dataset.target);
    $field.value = $previous.value;
    $field.focus();
    $field.dispatchEvent(new Event("change"));
  });
  // let $takeoverButton = document.querySelector(".ks-takeover-value");
  // if ($takeoverButton !== undefined) {
  //   $takeoverButton.addEventListener("click", (e) => {
  //     e.preventDefault();
  //     let $previous = document.querySelector("#previous_answer_value");
  //     document.querySelector($previous.dataset.target).value = $previous.value;
  //   });
  // }
}

if (document.readyState == "loading") {
  document.addEventListener("DOMContentLoaded", dashboard);
} else {
  dashboard();
}
